import React from 'react';
import { navigate, withPrefix } from 'gatsby';

const RedirectIndex = () => {
  if (typeof window !== 'undefined') {
    const langKey = 'en';
    const homeUrl = withPrefix(`/${langKey}/`);

    navigate(homeUrl);
  }
  return <div />;
};

export default RedirectIndex;
